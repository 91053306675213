/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';
import { PageLayout, PageHeading } from 'components';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { FAQPageType } from 'types';

const FAQPage: FC<FAQPageType> = ({ data }: any) => {
  const {
    title,
    tagline,
    faqs,
    banner,
    seo: { metaDescription, metaTitle, shareImage },
  } = data.strapiFaqPage;
  const { learningBanner } = data.strapiLearningHubPage;
  const Style = {
    height: 0,
  } as React.CSSProperties;
  useEffect(() => {
    setTimeout(() => {
      const faqItem = document.getElementsByClassName('faq-section-wrapper');
      const faqTitle = document.getElementsByClassName('faq-section-title');

      const removeActiveClass = () => {
        for (let i = 0; i < faqItem.length; i++) {
          faqItem[i].classList.remove('active');
          faqItem[i].children[1].style.height = '0px';
        }
      };

      for (let i = 0; i < faqTitle.length; i++) {
        faqTitle[i].addEventListener('click', () => {
          const isFaqActive = !faqItem[i].classList.contains('active');
          removeActiveClass();
          if (isFaqActive) {
            faqItem[i].classList.toggle('active');
            const height =
              faqItem[i].children[1].children[0].getBoundingClientRect()
                .height + 20;

            faqItem[i].children[1].style.height = `${height}px`;
          }
        });
      }
    }, 10);
  }, []);
  return (
    <PageLayout
      title="FAQ"
      metaTitle={metaTitle ?? title}
      description={metaDescription}
      image={shareImage?.localFile?.publicURL}
      hasContainerClass
      delaySec={0.2}
      bannerTitle={banner?.title || learningBanner.title}
      bannerTagline={banner?.tagline || learningBanner.tagline}
      bannerImage={banner?.image || learningBanner.image}
      bannerButton={banner?.ctaButton || learningBanner.ctaButton}
      bannerPlaceholder={
        banner?.postcodePlaceholder || learningBanner.postcodePlaceholder
      }
      faqs={faqs}
    >
      <>
        <PageHeading title={title} subtitle={tagline} level={1} />
        <section className="faq-page">
          <div className="faq-section">
            <div className="faq-section-item">
              {faqs.map(faqItem => (
                <div className="faq-section-wrapper">
                  <button type="button" className="faq-section-title">
                    <div className="toggle_icon" />
                    <p className="faq-section-title-question">
                      {faqItem.title}
                    </p>
                  </button>
                  <div className="faq-section-description" style={Style}>
                    <div className="faq-section-answer">
                      <ReactMarkdown className="md-html">
                        {faqItem.answer}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    </PageLayout>
  );
};

export default FAQPage;

export const query = graphql`
  query FAQPageQuery {
    strapiFaqPage {
      title
      seo {
        metaDescription
        metaTitle
      }
      tagline
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      faqs {
        id
        answer
        title
      }
    }
    strapiLearningHubPage {
      learningBanner: banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
